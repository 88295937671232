<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="字典名称：" prop="dictValue">
        <el-input v-model="ruleForm.dictValue" maxlength="48"></el-input>
      </el-form-item>
      <el-form-item
        label="字典键值："
        prop="dictKey"
        v-if="$route.params.id && $route.params.id !== ':id'"
      >
        <el-input
          v-model="ruleForm.dictKey"
          maxlength="32"
          @input="handleNumLength($event, ruleForm, 'dictKey')"
        ></el-input>
      </el-form-item>
      <el-form-item label="字典类型：" prop="code" v-else>
        <el-input v-model="ruleForm.code" maxlength="32"></el-input>
      </el-form-item>
      <template v-if="$route.params.id && $route.params.id !== ':id'">
        <el-form-item label="图片：" prop="imageUrl">
          <div class="upload-box">
            <div v-if="ruleForm.imageUrl" class="upload-box-show">
              <FileIcon2
                :showDelet="false"
                suffix="jpg"
                :fileUrl="ruleForm.imageUrl"
              ></FileIcon2>
              <div class="mask">
                <i style="cursor: pointer" class="el-icon-search"></i>
                <i
                  @click.stop="deletFile('imageUrl')"
                  style="cursor: pointer"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
            <div @click="addFile('imageUrl')" class="upload-box-add">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="图标：" prop="iconUrl">
          <div class="upload-box">
            <div v-if="ruleForm.iconUrl" class="upload-box-show">
              <FileIcon2
                :showDelet="false"
                suffix="jpg"
                :fileUrl="ruleForm.iconUrl"
              ></FileIcon2>
              <div class="mask">
                <i style="cursor: pointer" class="el-icon-search"></i>
                <i
                  @click.stop="deletFile('iconUrl')"
                  style="cursor: pointer"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
            <div @click="addFile('iconUrl')" class="upload-box-add">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </el-form-item>
      </template>
      <el-form-item label="状态：" prop="enabled">
        <el-radio-group v-model="ruleForm.enabled">
          <el-radio :label="true">正常</el-radio>
          <el-radio :label="false">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input
          v-model="ruleForm.sort"
          maxlength="32"
          @input="handleNumLength($event, ruleForm, 'sort')"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import { listTenant, dictSubmit } from "@/api/SystemData";
import { uploadFile } from "@/utils/index";
import { OSSDirectPass } from "@/api/oss.js";
import FileIcon2 from "@/components/DragMultipleUpload/FileIcon2";
export default {
  components: { FileIcon2 },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
        } else if (!v) {
          this.ruleForm = {
            enabled: true,
            dictValue: "",
            dictKey: "",
            code: "",
            sort: "",
            remark: "",
            iconUrl: "",
            imageUrl: "",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      ruleForm: {
        enabled: true,
        dictValue: "",
        dictKey: "",
        sort: "",
        code: "",
        remark: "",
        iconUrl: "",
        imageUrl: "",
      },
      rules: {
        dictValue: [
          { required: true, message: "请输入字典名称", trigger: "change" },
        ],
        dictKey: [
          { required: true, message: "请输入字典键值", trigger: "change" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "change" }],
        code: [
          { required: true, message: "请输入字典类型", trigger: "change" },
        ],
        remark: [
          {
            min: 0,
            max: 1000,
            message: "长度不能超过1000个字符",
            trigger: "change",
          },
        ],
      },
      loading: true,
      options: [],
    };
  },
  async created() {},
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {};
          if (this.$route.params.id && this.$route.params.id !== ":id") {
            obj = {
              parentId: this.$route.params.id,
              code: this.$route.params.code,
              dictKey: parseInt(this.ruleForm.dictKey),
            };
          } else {
            obj = {
              dictKey: -1,
            };
          }
          dictSubmit({ ...this.ruleForm, ...obj }).then((res) => {
            this.handleClose();
            this.$emit("changeList", 1);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          enabled: true,
          dictValue: "",
          dictKey: "",
          code: "",
          sort: "",
          remark: "",
          iconUrl: "",
          imageUrl: "",
        };
      }
    },
    handleNumLength(e, tiem, key) {
      tiem[key] = e.replace(/[^\d]/g, "");
      if (e.length > 11) {
        const aa = e + "";
        tiem[key] = aa.slice(0, 11);
      }
    },
    addFile(type = "imageUrl") {
      if (this.ruleForm[type]) {
        return this.$message.error("最多上传" + 1 + "个文件");
      }
      uploadFile({ accept: "image/*", fileSize: 10 }).then(async (res) => {
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        this.ruleForm[type] = url;
        this.$forceUpdate();
      });
    },
    deletFile(type = "imageUrl") {
      this.ruleForm[type] = "";
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
.upload-box {
  display: flex;
  &-add {
    cursor: pointer;
    width: 88px;
    height: 88px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px dashed #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-show {
    width: 88px;
    height: 88px;
    border-radius: 4px 4px 4px 4px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    &:hover {
      .mask {
        display: flex;
      }
    }
  }
}
.mask {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  color: #ffffff;
  align-items: center;
  justify-content: space-around;
}
</style>
