<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="operateTitle='添加', editData={},isAddVisible = true"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="DictionariesManagement"
      >
      <template slot="operate-left">
        <el-button type="primary"
          size="small"
          v-if="($route.params.id && $route.params.id !== ':id')"
          @click="handleBack">返回</el-button>
      </template>
      <template slot="enabled"
        slot-scope="{item}">{{ item.enabled ? '正常' : '停用' }}</template>
      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          type="text"
          @click="handlePathList(item)"
          v-if="(!$route.params.id || $route.params.id === ':id')"
          size="small">字典列表</el-button>
        <el-button class="polecy-button"
          @click.native.prevent="editItem(item)"
          v-if="handleHasPerms('M22-dictionaries-edit')"
          type="text"
          size="small">编辑</el-button>
        <el-button class="polecy-button polecy-button-no"
          @click.native.prevent="deleteItem([item])"
          v-if="handleHasPerms('M22-dictionaries-edit')"
          type="text"
          size="small">删除</el-button>
      </template>
    </GeneralTable>
    <addDictionariesDialog :dialogVisible.sync="isAddVisible"
      :title="operateTitle"
      :editData="editData"
      @changeList="changeList"></addDictionariesDialog>
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import addDictionariesDialog from "@/views/SystemManagement/components/dictionaries/addDictionariesDialog.vue";
import { dictList, dictRemove, getSystemRoleList, updateUser, exportDict } from "@/api/SystemData";
import { hasPerms } from "@/utils/auth";
export default {
  data() {
    return {
      option: {
        isAdd: hasPerms('M22-dictionaries-add'), //添加 事件 @add-change
        isDel: hasPerms('M22-dictionaries-edit'), //删除 @del-change
        isEdit: hasPerms('M22-dictionaries-edit'), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isPagination: true,
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [
          {
            label: '字典名称',
            prop: 'dictValue',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入字典名称"
          },
          {
            label: '字典类型',
            prop: 'code',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入字典类型"
          }, {
            label: '字典状态',
            prop: 'enabled',
            formType: 'select',
            filterable: true,
            placeholder: "请选择类型",
            selectList: [
              { value: '', text: '全部' },
              { value: true, text: '正常' },
              { value: false, text: '停用' },
            ]
          }, {
            label: '创建时间',
            formType: 'daterange',
            clearable: true,
            format: 'yyyy.MM.dd',
            prop: ['beginTime', 'endTime']
          }]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "字典名称",
          prop: "dictValue",
          align: 'center',
          isShow: true,
        },
        {
          label: "字典类型",
          prop: "code",
          align: 'center',
          isShow: true,
        },
        {
          label: '字典键值',
          prop: 'dictKey',
          align: 'center',
          isShow: true,
          isPermissions: false
        },
        {
          label: "状态",
          prop: "enabled",
          align: 'center',
          isShow: true,
          isSlot: true,
        },
        {
          label: "备注",
          prop: "remark",
          align: 'center',
          isShow: true,
        },
        {
          label: "排序",
          prop: "sort",
          align: 'center',
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 240,
          fixed: 'right',
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: '添加',
      isAddVisible: false,
      editData: {},
      isByUserVisible: false,
      roleId: '',
      passwordType: 'text'
    };
  },
  components: { GeneralTable, addDictionariesDialog },
  created() {
  },
  watch: {
    '$route'(to, from) {
      // if (to.params.id && to.params.id !== ':id') {

      // } else {

      // }
      if (to.name == "DictionariesManagement") {
        if (!to.params || to.params.id == ':id') {
          this.option.isPagination = true;
          this.column[3].isPermissions = false;
        } else {
          this.option.isPagination = false;
          this.column[3].isPermissions = true;
        }
        this.$nextTick(() => {
          this.changeList();
        });
      }
    }
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize) {
      const { code, data } = await dictList({ ...v, current: pageNum, size: (this.$route.params.id && this.$route.params.id !== ':id') ? 999 : pageSize, condition: v.keyword, parentId: (this.$route.params.id && this.$route.params.id !== ':id') ? this.$route.params.id : '', beginTime: v.beginTime ? v.beginTime + ' 00:00:00' : '', endTime: v.endTime ? v.endTime + ' 23:59:59' : '' });
      if (code === 200 && data.records && data.records.length) {
        this.claimRecordList = data.records;
        this.totalNum = data.total;
      } else {
        this.claimRecordList = [];
        this.totalNum = 0;
      }
    },
    changeList(type) {
      if (type) {
        this.$refs.GeneralTable.getList();
      } else {
        this.$refs.GeneralTable.handleCurrentChange(1);
      }
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm('确认删除该用户?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id).toString();
          dictRemove({
            ids,
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList(1);
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的角色！');
      }
    },
    // 编辑
    editItem(e) {
      e.password = '123456';
      this.editData = e;
      this.operateTitle = '编辑字典-' + e.dictValue;
      this.isAddVisible = true;
    },
    // 跳页面
    handleGoPath(path) {
      this.$router.push({
        path: path
      });
    },
    handlePathList(e) {
      this.$router.push({
        path: '/SystemManagement/DictionariesManagement/' + e.id + '/' + e.code
      });
    },
    handleUpdateUser() {
      if (this.editData.password.length < 6 || this.editData.password.length > 8) {
        this.$message.error('请输入正确的密码！');
        return false;
      }
      updateUser({
        ...this.editData,
        roleId: this.editData.roleId && this.editData.roleId.length ? this.editData.roleId.toString() : '',
        tenantId: this.$store.state.tenantId,
        yiLiUserToken: this.$store.state.appToken,
        nowUserId: this.$store.state.userInfo.userId
      }).then(res => {
        if (res) {
          this.isByUserVisible = false;
          this.$message.success('修改成功');
        }
      }).catch(err => {
        this.$message.error('修改失败！');
      });
    },
    exportData(e) {
      if (!e || !e.length) {
        this.$message.warning('请选择需要导出的字典！');
        return false;
      }
      exportDict({
        ids: e.map(val => val.id).toString()
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
.reset-password {
  display: flex;
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>